<template>
  <Accordion key="Language Preference" :initial-expanded="false">
    <SidebarNavItem
      :text="$i18n.locale"
      :level="1"
      :has-separator="false"
      :icon-alt-text="$t('notices.languagePreference')"
    >
      <template #icon>
        <GlobeIcon class="globe-icon" />
      </template>
    </SidebarNavItem>
    <template #content>
      <div>
        <SidebarNavItem
          v-for="language in languages"
          :key="language"
          :text="languageOptionText(language)"
          :level="2"
          :has-separator="false"
          @click="$i18n.setLanguagePreference(language)"
        />
      </div>
    </template>
  </Accordion>
</template>

<script>
import Accordion from '@sephora-asia/core-accordion/Accordion.vue'
import SidebarNavItem from '@sephora-asia/core-sidebar-nav-item/SidebarNavItem.vue'
import GlobeIcon from '~/assets/images/globe.svg?inline'

export default {
  name: 'SidebarLanguageSwitcher',

  components: {
    Accordion,
    SidebarNavItem,
    GlobeIcon
  },

  props: {
    languages: {
      type: Array,
      required: true
    }
  },

  methods: {
    languageOptionText(language) {
      return `${this.$t(`notices.${language}`)} (${language.toUpperCase()})`
    }
  }
}
</script>

<style lang="scss" scoped>
.globe-icon {
  width: 30px;
  height: 30px;
  fill: $black;
  margin-right: 15px;
  vertical-align: bottom;
}
</style>
